import * as React from 'react'
import classNames from 'classnames'

type ITestimonial = {
  name: string
  companyName: string
  jobTitle: string
  testimonial: string
  id: number
  image?: string
}

interface WindowWithTestimonials extends Window {
  testimonials: ITestimonial[]
  staticUrl: string
}

const Testimonials = () => {
  const testimonials = (window as unknown as WindowWithTestimonials).testimonials
  const staticUrl = (window as unknown as WindowWithTestimonials).staticUrl
  const [testimonial, setTestimonial] = React.useState<ITestimonial>(testimonials[0])

  return (
    <div className="bg-gray-800 w-full">
      <div className="px-5 lg:px-28 py-24 lg:pt-48 text-gray-50">
        <h2 className="text-4xl lg:font-bold text-center">
          Testimonials
        </h2>
        <p className="text-xl text-center">Lorem ipsum dolor sit</p>
        <img
          src={`${staticUrl}icon/quote.svg`}
          alt="quote"
          className="mx-auto mt-2 mb-4"
          width="80"
          height="80"
        />

        <div className="flex flex-col text-center max-w-2xl mx-auto mb-6">
          <h3 className="text-3xl leading-10 font-bold mb-6">“{ testimonial.testimonial }”</h3>
          <h4 className="font-bold">{ testimonial.name }</h4>
          <p>{ testimonial.jobTitle } @ {testimonial.companyName }</p>
        </div>

        <div className="flex flex-row justify-center">
        { testimonials.map((t) => (
          <TestimonialSelect
            key={t.id}
            isSelected={t.id === testimonial.id}
            testimonial={t}
            onClick={setTestimonial}
          />
        ))}
        </div>
      </div>
    </div>
  )
}

type TestimonialSelectProps = {
  testimonial: ITestimonial
  isSelected: boolean
  onClick: (testimonial: ITestimonial) => void
}

const TestimonialSelect: React.FC<TestimonialSelectProps> = (
  { testimonial, isSelected, onClick }
) => (
  <div className="m-1.5">
    <div className={classNames(
      'w-16 h-16 bg-gray-100',
      'rounded-full flex justify-center items-center border-4 border-double',
      { 'border-primary-dark': isSelected },
      { 'border-gray-300': !isSelected }
    )}>
      { testimonial.image &&
        <img
          src={ testimonial.image }
          className="rounded-full cursor-pointer"
          onClick={() => onClick(testimonial)}/>
      }
      { !testimonial.image &&
        <span className="text-3xl cursor-pointer" onClick={() => onClick(testimonial)}>
          👤
        </span>
      }
    </div>
  </div>
)

export default Testimonials
