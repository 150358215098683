import { createRoot } from 'react-dom/client'
import FAQ from '../components/FAQ'
import Testimonials from '../components/Testimonials'

const faqContainer = document.getElementById('faq-root') as HTMLElement

if (faqContainer) {
  const faqRoot = createRoot(faqContainer)
  faqRoot.render(<FAQ />)
}

const testimonialsContainer = document.getElementById('testimonials-root') as HTMLElement
if (testimonialsContainer) {
  const testimonialsRoot = createRoot(testimonialsContainer)
  testimonialsRoot.render(<Testimonials />)
}
