import classNames from 'classnames'
import * as React from 'react'

type IQuestion = {
  question: string
  answer: string
  id: number
}

interface WindowWithFaq extends Window {
  faq: IQuestion[]
  staticUrl: string
}

type Props = {
  standalone?: boolean
}

const FAQ: React.FC<Props> = ({ standalone = false }) => {
  const faq = (window as unknown as WindowWithFaq).faq
  const [openQuestion, setOpenQuestion] = React.useState<number | null>(faq[0].id)

  const toggleQuestion = (id: number) => {
    if (id === openQuestion) {
      setOpenQuestion(null)
    } else {
      setOpenQuestion(id)
    }
  }

  let heading: JSX.Element
  if (standalone) {
    heading = (
      <h1 className="text-4xl lg:text-6xl font-bold text-left text-gray-900">
        Frequently Asked Questions
      </h1>
    )
  } else {
    heading = (
      <h2 className="text-3xl lg:text-6xl font-bold text-left text-primary-dark">
        Frequently <span className="text-primary-base">Asked</span> Questions
      </h2>
    )
  }

  return (
    <div className="w-full">
      <div className={classNames(
        'container mx-auto px-5 lg:px-28 flex flex-col justify-between pb-24',
        { 'lg:flex-row': !standalone },
        { 'items-center': standalone }
      )}>
        <div className={classNames(
          'mb-8',
          { 'max-w-sm lg:mb-0 px-5': !standalone },
          { 'lg:mb-16 w-full lg:w-2/3': standalone }
        )}>
          { heading }
          <p className={classNames(
            'mt-6 text-xl',
            { 'text-primary-dark': !standalone },
            { 'text-gray-800': standalone }
          )}>
            Welcome to our FAQ section—your one-stop resource for quick answers to common queries.
            If your question {"isn't"} covered, our dedicated customer support team is ready to assist.
          </p>
        </div>

        <div className={classNames(
          'border border-primary-dark',
          { 'lg:max-w-3xl': !standalone },
          { 'w-full': standalone }
        )}>
          { faq.map((question, index) => (
            <Question
              key={index}
              question={question}
              isOpen={question.id === openQuestion}
              onClick={toggleQuestion}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

type QuestionProps = {
  question: IQuestion
  isOpen: boolean
  onClick: (id: number) => void
}

const Question: React.FC<QuestionProps> = ({ question, isOpen, onClick }) => {
  const staticUrl = (window as unknown as WindowWithFaq).staticUrl
  const openContent = (
    <div
      className="
        w-full flex flex-row justify-between items-start
        cursor-pointer
      "
      onClick={() => onClick(question.id)}
    >
      <div className="flex flex-col">
        <p className="text-primary-dark font-bold mb-4">{ question.question}</p>
        <p className="text-primary-dark">{ question.answer}</p>
      </div>
      <img
        src={`${staticUrl}icon/minus.svg`}
        className="pl-8 cursor-pointer"
        alt="Close question"
      />
    </div>
  )
  const closeContent = (
    <div
      className="w-full flex flex-row justify-between items-start cursor-pointer"
      key={question.id}
      onClick={() => onClick(question.id)}
    >
      <p className="font-bold">{ question.question }</p>
      <img
        src={`${staticUrl}icon/plus.svg`}
        className="pl-8"
        alt="Open answer"
      />
    </div>
  )

  return (
    <div className="
      px-5 py-10
      border-b border-primary-dark last:border-b-0 hover:bg-primary-lighter
    ">
      { isOpen ? openContent : closeContent }
    </div>
  )
}

export default FAQ
